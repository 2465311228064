<template lang="">
  <div class="tourismasso">
    <Header class="hide" />
    <Headers class="dispy" />
    <BannerImage rulename="tourismmana_banner" />
    <div class="w">
      <Breadcrumb :crumblist="crumbdata" class="hide" />
      <div class="strip">
        <div class="car">
          旅游协会
        </div>
      </div>
      <div class="navig-box">
        <ul class="navig-list">
          <li class="navig-item" v-for="item in result" :key="item.id">
            <el-row :gutter="26">
              <el-col :md="6"></el-col>
              <el-col :md="26">
                <div class="navig-info">
                  <router-link
                    :to="{ path: 'tourismdata', query: { id: item.id } }"
                  >
                    <h4 class="title">{{ item.theTitle }}</h4>
                    <p class="desc">{{ item.theInfo }}</p>
                    <p class="time">
                      {{ item.createTime.substring(0, 10) }}
                      <i class="fa fa-long-arrow-right arrow lr"></i>
                    </p>
                  </router-link>
                </div>
              </el-col>
            </el-row>
          </li>
        </ul>
        <Pagi
          :total="pagi.total"
          :current="pagi.current"
          :sizes="pagi.size"
          @pagicurrent="togglePagi"
        />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import Footer from "@/components/main/Footer";
import OnerowLists from "@/components/main/OnerowLists";
import BannerImage from "@/components/main/BannerImage";
import Pagi from "@/components/Pagi";
import { getAction } from "@/api/manage";
export default {
  created() {
    this.loadData(1);
  },
  components: {
    Breadcrumb,
    Header,
    Headers,
    Footer,
    OnerowLists,
    BannerImage,
    Pagi,
  },
  data() {
    return {
      result: [],
      pagi: {
        total: 0,
        current: 1,
        size: 10,
      },
      crumbdata: [
        { name: "旅游管理", url: "" },
        { name: "旅游协会", url: "/Tourismasso" },
      ],
    };
  },
  methods: {
    loadData(value) {
      getAction("/association/tourAssociation/list", {
        pageNo: value,
      }).then((res) => {
        if (res.success) {
          this.result = res.result.records;
          this.pagi.total = res.result.total;
          this.pagi.current = res.result.current;
          this.pagi.size = res.result.size;
        }
      });
    },
    togglePagi(value) {
      this.loadData(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.navig-box {
  margin-bottom: 50px;
  padding: 0 18px 0 18px;
  .navig-list {
    list-style: none;
    min-height: 400px;
    .navig-item {
      border-bottom: 1px dashed #dbdbdb;
      padding: 25px 0;
      .imgleft {
        width: 100%;
        height: 184px;
      }
      .navig-info {
        padding: 10px 0 9px;
        .title {
          font-size: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          transition: all 0.5s;
        }
        .desc {
          margin: 20px 0;
          height: 78px;
          font-size: 14px;
          line-height: 26px;
          color: #999999;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-align: justify;
        }
        .time {
          color: #999999;
          font-size: 14px;
          .arrow {
            font-size: 20px;
            transition: all 0.5s;
          }
        }
      }
      &:hover {
        .title {
          color: #6EB400;
        }
        .time {
          .arrow {
            color: #6EB400;
          }
        }
      }
    }
  }
}
</style>
